.app{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.mask{
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.452);
}
.left_side{
  width: 100%;
  height: 100vh;
  background-image: url('/src/img/bj_u1.png');
  background-repeat: no-repeat;
  background-position: center;
}
.right_side{
  height: 100vh;
  flex:0 0 32rem;
  background-color: white;
}
.app_logo{
  width: 8rem;
  height: 3rem;
  background-image: url('/src/img/u8.png');
  position: absolute;
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  left:2rem;
  top: 2rem;
}
.app_title{
  color: white;
  font-size: 4rem;
  height: 50%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
}
.app_bottom{
  height: 3rem;
  color: white;
  position: absolute;
  bottom:1rem;
  font-size:2rem;
  margin-left: 5rem;
  line-height: 5rem;
  font-size: 15px;

}
a{
  color: white;
}
.login_box{
  margin-top: 15rem;
  height: 30%;
  padding: 2rem 4rem;
}
.login_title{
  color: rgb(28, 97, 177);
  font-size: 30px;
  line-height: 5rem;
}
.welcome{
  color: rgb(28, 97, 177);
  font-size: 16px;
}
input{
  width: 80%;
  height: 2.5rem;
  margin: 1rem 0;
  border: 1px solid  rgb(35, 192, 197);
  padding: 0rem .5rem;
}
.do_login{
  width: 80%;
  height: 2.5rem;
  padding: 0rem .5rem;
  background-color: rgb(52, 138, 218);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.do_login:hover{
  background-color: rgb(35, 192, 197);
}